* {
  font-size: 18px;
}

h1 {
  font-size: 23px;
}
form {
  display: grid;
  width: 60%;
  margin: auto;
  margin-bottom: 200px;

  label {
    display: grid;
  }

  .fastForm {
    display: grid;
    grid-template-columns: 20% 20% 1fr;
  
  .radio {
    grid-template-columns: 25px 1fr;
    margin-bottom: 20px;

    input {
      justify-self: start;
      padding: 10px;
    }
  }
}

  input[type='text'], textarea {
    margin-bottom: 30px;
    margin-top: 5px;
  }

}


.footer {
  position: fixed;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  bottom: 0;
  right: 0;
  background-color: #f4f4f4;
  width: 100%;
  padding: 10px;

  button {
    border: 2px solid orange;
    border-radius: 2%;
    margin: auto;
    background-color: transparent;
    font-size: 16px;
    padding: 5px 15px;
    margin-bottom: 20px;
    color: orange;

    &:hover {
      background-color: orange;
      color: white;
    }

    &:disabled {
      background-color: grey;
      border: 2px solid gray;
      color: white;
      cursor: not-allowed;
    }
  }

  .submit {
    justify-self: flex-end;
    align-items: flex-end;
  }
}